import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { lightTheme } from './styles/themes/light';
import { darkTheme } from './styles/themes/dark';
import { GlobalStyles } from './styles/GlobalStyles';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import WelcomePage from './pages/WelcomePage';
import WithdrawalPage from './pages/WithdrawalPage';
import DepositPage from './pages/DepositPage';
import DocPage from './pages/DocPage';
import SalesPage from './pages/SalesPage';

const App: React.FC = () => {
  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  };

  const currentTheme = theme === 'light' ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <Router>
        {/* <button onClick={toggleTheme} style={{ position: 'absolute', top: 10, right: 10 }}>
          Toggle Theme
        </button> */}
        <Routes>
        <Route path="/" element={<SalesPage />} />
          {/* <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/documentation" element={<DocPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/login-alt" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/saque" element={<WithdrawalPage />} />
          <Route path="/deposito" element={<DepositPage />} />
          <Route path="/home" element={<HomePage />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
